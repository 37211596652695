<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.address.label')"
                        :label-for="'address.' + language.code"
                      >
                        <b-form-input
                          :id="'address.' + language.code"
                          v-model="item.address[language.code]"
                          :state="errors && errors['address.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['address.' + language.code]">
                          {{ errors['address.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.schedule.label')"
                        :label-for="'schedule.' + language.code"
                      >
                        <b-form-input
                          :id="'schedule.' + language.code"
                          v-model="item.schedule[language.code]"
                          :state="errors && errors['schedule.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['schedule.' + language.code]">
                          {{ errors['schedule.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.schedule2.label')"
                        :label-for="'schedule2.' + language.code"
                      >
                        <b-form-input
                          :id="'schedule2.' + language.code"
                          v-model="item.schedule2[language.code]"
                          :state="errors && errors['schedule2.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['schedule2.' + language.code]">
                          {{ errors['schedule2.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.data.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.phone1.label')"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="item.phone"
                      :state="errors && errors.phone ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.phone">
                      {{ errors.phone[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.phone2.label')"
                    label-for="phone2"
                  >
                    <b-form-input
                      id="phone2"
                      v-model="item.phone2"
                      :state="errors && errors.phone2 ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.phone2">
                      {{ errors.phone2[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.phone3.label')"
                    label-for="phone3"
                  >
                    <b-form-input
                      id="phone3"
                      v-model="item.phone3"
                      :state="errors && errors.phone3 ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.phone3">
                      {{ errors.phone3[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.phone4.label')"
                    label-for="phone4"
                  >
                    <b-form-input
                      id="phone4"
                      v-model="item.phone4"
                      :state="errors && errors.phone4 ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.phone4">
                      {{ errors.phone4[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.facebook.label')"
                    label-for="facebook"
                  >
                    <b-form-input
                      id="facebook"
                      v-model="item.facebook"
                      :state="errors && errors.facebook ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.facebook">
                      {{ errors.facebook[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.instagram.label')"
                    label-for="instagram"
                  >
                    <b-form-input
                      id="instagram"
                      v-model="item.instagram"
                      :state="errors && errors.instagram ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.instagram">
                      {{ errors.instagram[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.youtube.label')"
                    label-for="youtube"
                  >
                    <b-form-input
                      id="youtube"
                      v-model="item.youtube"
                      :state="errors && errors.youtube ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.youtube">
                      {{ errors.youtube[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    :label="$t('form.email.label')"
                    label-for="href"
                  >
                    <b-form-input
                      id="email"
                      v-model="item.email"
                      :state="errors && errors.email ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.email">
                      {{ errors.email[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.google_map.label')"
                    label-for="href"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.href"
                      :state="errors && errors.href ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.href">
                      {{ errors.href[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.latitude.label')"
                    label-for="latitude"
                  >
                    <b-form-input
                      id="latitude"
                      v-model="item.latitude"
                      :state="errors && errors.latitude ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.latitude">
                      {{ errors.latitude[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.longitude.label')"
                    label-for="longitude"
                  >
                    <b-form-input
                      id="longitude"
                      v-model="item.longitude"
                      :state="errors && errors.longitude ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.longitude">
                      {{ errors.longitude[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'shops-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BImg,
  BTable,
  BFormInvalidFeedback,
  BOverlay, BMedia, BMediaBody, BMediaAside, BLink, BFormFile, BFormTextarea,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BFormFile,
    BFormTextarea,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormInvalidFeedback,
    BOverlay,
  },
  data() {
    return {
      item: null,
      languages: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/admin/shops/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'shops-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    onSubmit() {
      const data = {
        title: this.item.title,
        address: this.item.address,
        schedule: this.item.schedule,
        schedule2: this.item.schedule2,
        href: this.item.href,
        email: this.item.email,
        phone: this.item.phone,
        phone2: this.item.phone2,
        phone3: this.item.phone3,
        phone4: this.item.phone4,
        facebook: this.item.facebook,
        instagram: this.item.instagram,
        youtube: this.item.youtube,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
      }

      this.$http.put(`/api/admin/shops/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'shops-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        href: item.href,
        email: item.email,
        phone: item.phone,
        phone2: item.phone2,
        phone3: item.phone3,
        phone4: item.phone4,
        latitude: item.latitude,
        longitude: item.longitude,
        facebook: item.facebook,
        instagram: item.instagram,
        youtube: item.youtube,
        title: {},
        address: {},
        schedule: {},
        schedule2: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.address[language.code] = translation ? translation.address : null
        data.schedule[language.code] = translation ? translation.schedule : null
        data.schedule2[language.code] = translation ? translation.schedule2 : null
      })

      return data
    },
  },
}
</script>
